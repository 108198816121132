
//this is for hidden context-menu in every where
window.addEventListener("click", function () {
    if (document.getElementById("context-menu")) {
        document.getElementById("context-menu").classList.remove("active");

    }
});

window.toolsFunction = {
    'changeBarcodeCount': function () {
        if (typeof window.rppbarcodeoptions === "undefined")
            return;
        if (jQuery('.barcode-count input').val() == 0)
            return;
        if (jQuery('.barcode-count input').val() > 1000)
            return;

        window.rppbarcodeoptions.bcount = parseInt(jQuery('.barcode-count input').val());
        renderBarcodes();
        setBarcodeWrapperStyle();
    },
    'changeBarcodeColumns': function () {
        if (typeof window.rppbarcodeoptions === "undefined")
            return;
        if (jQuery('.barcode-columns input').val() == 0)
            return;
        if (jQuery('.barcode-columns input').val() > 15)
            return;

        window.rppbarcodeoptions.bcols = parseInt(jQuery('.barcode-columns input').val());
        setBarcodeWrapperStyle();
    },
    'changeBarcodePrintTopOffset': function () {
        if (typeof window.rppbarcodeoptions === "undefined")
            return;
        if (jQuery('.barcode-continer-top-offset input').val() < 0)
            return;

        window.rppbarcodeoptions.bcto = parseInt(jQuery('.barcode-continer-top-offset input').val());
        setBarcodeWrapperStyle();
    },
    'changeBarcodeContainerColumnGap': function () {
        if (typeof window.rppbarcodeoptions === "undefined")
            return;
        if (jQuery('.barcode-container-column-gap input').val() < 0)
            return;
        if (jQuery('.barcode-container-column-gap input').val() > 50)
            return;

        window.rppbarcodeoptions.bccg = parseInt(jQuery('.barcode-container-column-gap input').val());
        setBarcodeWrapperStyle();
    },
    'changeBarcodeContainerRowGap': function () {
        if (typeof window.rppbarcodeoptions === "undefined")
            return;
        if (jQuery('.barcode-container-row-gap input').val() < 0)
            return;
        if (jQuery('.barcode-container-row-gap input').val() > 50)
            return;

        window.rppbarcodeoptions.bcrg = parseInt(jQuery('.barcode-container-row-gap input').val());
        setBarcodeWrapperStyle();
    },
    'changeBarcodeContainerWidth': function () {
        if (typeof window.rppbarcodeoptions === "undefined")
            return;
        window.rppbarcodeoptions.bcw = parseInt(jQuery('.barcode-continer-width input').val());
        setBarcodeContainerStyle();
        setBarcodeWrapperStyle();
    },
    'changeBarcodeContainerHeight': function () {
        if (typeof window.rppbarcodeoptions === "undefined")
            return;
        window.rppbarcodeoptions.bch = parseInt(jQuery('.barcode-continer-height input').val());
        setBarcodeContainerStyle();
        setBarcodeWrapperStyle();
    },
    'changeBarcodeWidth': function () {
        if (typeof window.rppbarcodeoptions === "undefined")
            return;
        window.rppbarcodeoptions.bw = parseInt(jQuery('.barcode-width input').val());
        setBarcodeStyle();
    },
    'changeBarcodeHeight': function () {
        if (typeof window.rppbarcodeoptions === "undefined")
            return;
        window.rppbarcodeoptions.bh = parseInt(jQuery('.barcode-height input').val());
        setBarcodeStyle();
    },
    'toggleBarcodeContainerBorder': function () {
        if (typeof window.rppbarcodeoptions === "undefined")
            return;
        var isChecked = jQuery('.barcode-border .form-switch input').is(':checked');
        if (isChecked)
            window.rppbarcodeoptions.bcbenable = 1;
        else
            window.rppbarcodeoptions.bcbenable = 0;

        setBarcodeContainerStyle();
    },
    'toggleBarcodeContainerBorderSize': function () {
        if (typeof window.rppbarcodeoptions === "undefined")
            return;

        if (jQuery('.barcode-column input').val() == 0.5)
            return;
        if (jQuery('.barcode-column input').val() > 5)
            return;

        window.rppbarcodeoptions.bcbsize = parseFloat(jQuery('.barcode-border-size input').val());

        if (!window.rppbarcodeoptions.bcbenable)
            return;
        setBarcodeContainerStyle();
        setBarcodeWrapperStyle();
    },
    'toggleBarcodeContainerBorderType': function () {
        if (typeof window.rppbarcodeoptions === "undefined")
            return;
        window.rppbarcodeoptions.bcbtype = jQuery('.barcode-border-type select').val();
        if (!window.rppbarcodeoptions.bcbenable)
            return;
        setBarcodeContainerStyle();
    },
    'saveBorderSettings': function () {
        if (typeof window.rppbarcodeoptions === "undefined")
            return;

        if (jQuery('.savesettingloader').length > 0)
            return;

        jQuery('body').append(
            jQuery('<div />', {
                class: "savesettingloader",
            }).append(
                jQuery('<span />').text('در حال انجام عملیات ...').prepend(
                    jQuery('<i />', {
                        class: "icon-save"
                    })
                )
            )
        );

        jQuery.ajax({
            type: "POST",
            url: "https://arashfakhrsrc.com/administrator/index.php?option=com_rppfixingcenter&task=savebarcodesettings",
            data: { params: window.rppbarcodeoptions, "5c325e8a4d7dc53de338b5eda07179d0": 1 },
            success: function (data, textStatus, jqXHR) {
                if (data && typeof data === "object" && !data.result)
                    alert('عملیات با خطا رو به رو شد.' + "\n" + data.message);
                else if (data && typeof data === "object" && data.result)
                    alert('تنظیمات چاپ بارکد با موفقیت ذخیره شد و برای دفعات بعدی به صورت پیشفرض اعمال خواهد شد.');
                else
                    alert('عملیات با خطا رو به رو شد، لطفا مجدد تلاش کنید. در صورت تکرار با پشتیبانی تماس بگیرید');
                jQuery('.savesettingloader').remove();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('عملیات با خطا رو به رو شد، لطفا مجدد تلاش کنید. در صورت تکرار با پشتیبانی تماس بگیرید');
                jQuery('.savesettingloader').remove();
            },
            dataType: 'json'
        });
        //console.log(window.rppbarcodeoptions);
    },
}
jQuery(document).ready(function () {
    window.rppbarcodeoptions = jQuery.parseJSON('{"bcount":5,"bcols":1,"bw":38,"bh":4,"bcto":0,"bcw":45,"bch":15,"bccg":0,"bcrg":0,"bcbenable":0,"bcbsize":0.5,"bcbtype":"solid","bcbcolor":"#000000","bcbside":"in"}');
    setBarcodeNumber();
    //number inputs select text on click and focus
    jQuery('.number-input input').focus(function () {
        jQuery(this).select();
    });

    //number inputs + and - click action, ios switch toggle action, buttons click action
    jQuery('.number-input button.minus, .number-input button.plus, .form-switch, .toolbar-tool .tool-body button').click(function (e) {
        if (typeof window.toolsFunction === "undefined")
            return;
        var f = jQuery(this).parents('.toolbar-tool').data('change-function');
        if (typeof window.toolsFunction[f] === "undefined")
            return;
        window.toolsFunction[f]();
    });

    //number inputs keyboard input action
    jQuery('.number-input input').keyup(function (e) {
        if (typeof window.toolsFunction === "undefined")
            return;
        var f = jQuery(this).parents('.toolbar-tool').data('change-function');
        if (typeof window.toolsFunction[f] === "undefined")
            return;
        window.toolsFunction[f]();
    });

    //select input change action
    jQuery('.toolbar-tool .tool-body select').change(function (e) {
        if (typeof window.toolsFunction === "undefined")
            return;
        var f = jQuery(this).parents('.toolbar-tool').data('change-function');
        if (typeof window.toolsFunction[f] === "undefined")
            return;
        window.toolsFunction[f]();
    });

    jQuery('.barcode-toolbar-toogle').click(function (e) {
        jQuery('body').toggleClass('toolbar-active');
    });

});

function setBarcodeWrapperStyle() {
    if (typeof window.rppbarcodeoptions === "undefined")
        return;

    var cwidth = (window.rppbarcodeoptions.bcols * window.rppbarcodeoptions.bcw) + ((window.rppbarcodeoptions.bcols - 1) * window.rppbarcodeoptions.bccg);

    if (window.rppbarcodeoptions.bcbenable && window.rppbarcodeoptions.bcbside == "out")
        cwidth += (window.rppbarcodeoptions.bcbsize * window.rppbarcodeoptions.bcols * 2);

    jQuery('.printarea').css({
        width: cwidth + "mm",
        margin: window.rppbarcodeoptions.bcto + 'mm auto 0',
        gap: window.rppbarcodeoptions.bcrg + 'mm ' + window.rppbarcodeoptions.bccg + 'mm',
    });
}

function setBarcodeNumber() {
    return;
    jQuery('.printarea > div > span.counter').remove();
    jQuery('.printarea > div').each(function (i) {
        jQuery(this).append(
            jQuery('<span>', {
                class: "counter",
                style: "position:absolute; top: 1px; left: 1px"
            }).text(++i)
        );
    });
}

function setBarcodeContainerStyle() {
    if (typeof window.rppbarcodeoptions === "undefined")
        return;
    var styleString = " width: " + window.rppbarcodeoptions.bcw + "mm;"
        + " height: " + window.rppbarcodeoptions.bch + "mm;";
    if (window.rppbarcodeoptions.bcbenable)
        styleString += " border: " + window.rppbarcodeoptions.bcbsize + "mm " + window.rppbarcodeoptions.bcbtype + " " + window.rppbarcodeoptions.bcbcolor + ";";
    if (window.rppbarcodeoptions.bcbenable && window.rppbarcodeoptions.bcbside == "in")
        styleString += " box-sizing: border-box;";

    jQuery('.printarea > *').attr("style", styleString);
}

function setBarcodeStyle() {
    if (typeof window.rppbarcodeoptions === "undefined")
        return;

    var styleString = " width: " + window.rppbarcodeoptions.bw + "mm;"
        + " height: " + window.rppbarcodeoptions.bh + "mm;";

    jQuery('.printarea > * img').attr("style", styleString);
}

function renderBarcodes() {
    if (typeof window.rppbarcodeoptions === "undefined")
        return;

    var barcodeHtml = jQuery('.printarea > *').first()[0].outerHTML;
    jQuery('.printarea').html("");

    for (var i = 0; i < window.rppbarcodeoptions.bcount; i++) {
        jQuery('.printarea').append(barcodeHtml);
    }
    setBarcodeNumber();
}